<template>
  <div>
    <big-title> Número de <span>teléfono</span>. </big-title>
    <vx-card>
      <div class="vx-row">
        <div class="vx-col w-full">
          <h2>Número actual: <span class="text-primary">{{ user.phone }}</span></h2>
          <label
            >Tu número puede usarse para enviarte notificaciones importantes o
            ayudarte a acceder a tu cuenta, mantenlo actualizado.</label
          >
          <vs-divider color="dark"></vs-divider>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full">
          <h4>¿Deseas cambiar tu número de teléfono?</h4>
          <label>Para asegurarse de que este número sea tuyo, RedGirasol te enviará un <strong> código de verificación </strong>.
          <small class="bold" v-if="isUsaPhone">
            <br>
            (Por el momento los télefonos de Estados Unidos no serán verificados)
          </small>          
          </label>
          <div class="vx-row">
            <div :class="colClass">
              <div class="flex">
                <vue-country-code
                  name="dialCode"
                  class="vs-custom"
                  data-vv-name="dialCode"
                  :enableSearchField="true"
                  searchPlaceholderText="Buscar código de país"
                  :enabledCountryCode="true"
                  @onSelect="onSelect"
                  :preferredCountries="['MX']"
                  :danger="hasError('dialCode')"
                  :danger-text="errorText('dialCode')"
                  :success="isSuccess('dialCode')"
                >
                </vue-country-code>
                <vs-input
                  label=""
                  name="phone"
                  type="tel"
                  v-validate="'required|numeric'"
                  v-model.lazy="phone"
                  :danger="hasError('phone')"
                  :danger-text="errorText('phone')"
                  :success="isSuccess('phone')"
                  icon-pack="feather"
                  placeholder="(Requerido)"
                />
              </div>
            </div>
          </div>
          <vs-alert
            v-if="errorMssg"
            icon-pack="feather"
            icon="icon-alert-triangle"
            class="mb-5"
            color="danger"
          >
            <span class="font-regular">{{ errorMssg }}</span>
          </vs-alert>
          <div class="vx-row">
            <div class="vx-col w-full">
              <vs-button @click="changeUserPhone">{{saveBtnText}}</vs-button>
            </div>
          </div>
        </div>
      </div>
    </vx-card>
    <vs-popup
      :active.sync="isSmsCodePopupActive"
      title="Código de verificación"
    >
      <div class="p-4 pt-0">
        <div>
          <h6 class="title-dark">
            Acabamos de enviarte un SMS con un código. Ingrésalo para verificar tu teléfono.
            ¿No recibiste un SMS? <span><router-link class="bold ml-1 inline-link-primary" to="" @click.native="reloadPage">Volver a intentarlo.</router-link></span></h6>
          <vs-alert
            v-if="errorCodeMssg"
            color="danger"
            class="mt-3 mb-3"
            style="height: auto"
            >{{ errorCodeMssg }}</vs-alert
          >
          <div class="vx-row mt-4">
            <div class="vx-col w-full">
              <div class="flex items-center flex-grow justify-left mt-1">
                <vs-input
                  type="text"
                  v-model.lazy="verificationCode"
                  name="verificationCode"
                  v-validate="'required|numeric'"
                  maxlength="6"
                  autocomplete="off"
                  :disabled="validatingCode"
                  v-on:keyup.enter="verifyCode"
                  placeholder="Ingresa el código"
                />
                <vs-button
                  class="ml-6"
                  color="primary"
                  :disabled="validatingCode"
                  @click="verifyCode"
                  >Verificar</vs-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import inputHelper from '@mixins/inputHelper'
export default {
  name: 'InvestorPhone',
  mixins: [inputHelper],
  data() {
    return {
      saveTemporaryPhoneStep: 1,
      verifyCodeStep: 2,
      updatePhoneNoVerificationStep: 3,
      countryCallingCodeUsa: "+1",
      currentProcessStep: null,
      requiredRules: 'required',
      errorMssg: null,
      errorCodeMssg: null,
      successMssg: null,
      colClass: 'vx-col w-full mb-5',
      requiredPlaceholder: '(Requerido)',
      countryCallingCode: null,
      phone: null,
      verificationCode: null,
      processStep: null,
      isSmsCodePopupActive: false,
      validatingCode: false
    }
  },
  computed:{
    isUsaPhone(){
      return (this.countryCallingCode == this.countryCallingCodeUsa);
    },
    saveBtnText(){
      return this.isUsaPhone ? "Cambiar número" : "Obtener código";
    },
  },
  methods: {
    async changeUserPhone() {
      this.errorMssg = null
      if (this.phone === null) {
        this.errorMssg = 'Debes ingresar un télefono.'
        setTimeout(() => (this.errorMssg = null), 5000)
        return
      }
      let processStep = this.saveTemporaryPhoneStep;
      if (this.isUsaPhone) {
         processStep = this.updatePhoneNoVerificationStep;
      }
      let apiSuccess = await this.executePostApi(processStep)
      if (apiSuccess) {
         if (processStep == this.updatePhoneNoVerificationStep) {
            await this.reloadPage();
         } else {
            this.isSmsCodePopupActive = true;
         }
      }
    },
    async verifyCode() {
      this.errorCodeMssg = null;
      let apiSuccess = await this.executePostApi(this.verifyCodeStep);
      if (apiSuccess) {
        this.isSmsCodePopupActive = false;
        await this.reloadPage();
      }
    },
    async executePostApi(processStep) {
      this.currentProcessStep = processStep
      let success = false
      this.errorMssg = null
      this.showLoading(true, 'Enviando información...')
      try {
        let payload = {
          user_id: this.UserId,
          phone: this.phone,
          country_calling_code: this.countryCallingCode,
          process_step: processStep,
          verification_code: this.verificationCode
        }
        await axios.post('/api/perfil/updateAndVerifyUserPhone', payload)
        success = true
        this.showLoading(false)
      } catch (e) {
        this.showLoading(false)
        this.showError(e)
      }
      return success
    },
    async reloadPage(){
      await this.$router.go();
    },
    onSelect({ dialCode }) {
      this.countryCallingCode = `+${dialCode}`
    },
    hasError(val) {
      return this.errors.has(val)
    },
    errorText(val) {
      return this.errors.first(val)
    },
    isSuccess(val) {
      let ff = this.fields[val]
      return ff && ff.required && ff.valid
    },
    showError(e) {
      this.warn(e)
      let err = 'Ha ocurrido un error con la operación, intente más tarde'
      if (e.response && e.response.data && e.response.data.message) {
        err = e.response.data.message
      }
      if (this.currentProcessStep == 1) {
        this.errorMssg = err
        setTimeout(() => (this.errorMssg = null), 5000)
      } else {
        this.errorCodeMssg = err
        setTimeout(() => (this.errorCodeMssg = null), 5000)
      }
    }
  }
}
</script>
